import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import {
  useGetClinicDetails,
  useGetProfileStatus,
  useGetProviderDetails,
} from "../../hooks/useProvider";
import assets from "../../utils/Assets";
import OTPVerificationContainer from "../otpVerification/OTPVerificationContainer";
import MainMenu from "./MainMenu";
import ProviderLoginMenuItems from "./ProviderLoginMenuItems";
import SidebarMenu from "./SidebarMenu";
import withClickOutside from "./withClickOutside";

const ProvidersMenu = React.forwardRef(({ setOpen, open }, ref) => {
  const { refetch, data: logoutData } = useLogout();
  const useAuth = () => {
    const user = localStorage.getItem("userID");
    return Boolean(user);
  };
  const user = useAuth();
  const location = useLocation();
  const navigation = useNavigate();

  const { data: providerData } = useGetProviderDetails(
    localStorage.getItem("userID") ? localStorage.getItem("userID") : ""
  );
  const { data: profileCompletionData } = useGetProfileStatus(
    localStorage.getItem("userID")
  );
  const { data: clinicData } = useGetClinicDetails(
    providerData?.provider?.providerId
  );

  useEffect(() => {
    if (clinicData?.status === "SUCCESS" && clinicData?.clinicProvider) {
      localStorage?.setItem(
        "clinicId",
        clinicData?.clinicProvider[0]?.clinicId
      );
    }
  }, [clinicData]);
  const logout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      localStorage.removeItem("auth");
    }
    localStorage.removeItem("userID");
    localStorage.removeItem("providerId");
    localStorage.removeItem("clinicId");
    localStorage.removeItem("providerUn");
    navigation("/login");
    setOpen(!open);
  };

  useEffect(() => {
    if (providerData?.status === "SUCCESS") {
      localStorage.setItem("providerId", providerData?.provider?.providerId);
      localStorage.setItem("providerUn", providerData?.provider?.userName);
      localStorage.setItem(
        "isSCAdmin",
        providerData?.provider?.secondOpinionAdmin
      );
    }
  }, [providerData]);

  return (
    <>
      {user && (
        <div className="navbar" ref={ref}>
          <div className="navbar__left">
            <div className="navbar__logo">
              <img
                src="https://static.meragoapp.com/images/menuLogo.png"
                alt="logo"
                width="55px"
                height="55px"
              />
            </div>
            <div className="navbar__items">
              {providerData?.provider?.providerVerificationStatus ===
                "VERIFIED_AND_APPROVED" && (
                <MainMenu
                  providerData={providerData?.provider}
                  clinicData={clinicData}
                />
              )}
            </div>
          </div>
          <div className="sideBar__main" onClick={() => setOpen(!open)}>
            <p>
              {providerData?.provider?.person.salutation?.salutation}{" "}
              {providerData?.provider?.person?.name}
            </p>
            <img
              src={assets.downArrow}
              alt="merago"
              width="15px"
              height="15px"
            />
          </div>
          {open ? (
            <div className="sidebar__conatiner">
              <SidebarMenu
                setOpen={setOpen}
                open={open}
                providerData={providerData?.provider}
                clinicData={clinicData}
                profileCompletionData={profileCompletionData}
              />
              {location.pathname !== "/login" && (
                <button onClick={logout}>Logout</button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <ProviderLoginMenuItems />
      {providerData?.provider?.providerVerificationStatus ===
        "VERIFIED_AND_APPROVED" &&
        profileCompletionData?.serviceInfo === true &&
        !providerData?.provider?.mobileConfirmed && (
          <OTPVerificationContainer />
        )}
    </>
  );
});

export default withClickOutside(ProvidersMenu);
